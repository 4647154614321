/**
 * Lot Property Search
 *
 * Handles additional lot/property search functionality
 * on the "Now Selling" page
 *
 */

const nowSellingFormEl = document.querySelector('#nowSelling')
const initNowSellingFilterSort = (form) => {
	const filterElement = document.getElementById('lotPropertyFilter') // the filter select element
	const sortElement = document.getElementById('lotPropertySort') // the sort select element

	if (filterElement) {
		filterElement.addEventListener('change', (e) => {
			form.querySelector('input[name="filter"]').value = e.target.value
			form.submit()
		})
	}

	if (sortElement) {
		sortElement.addEventListener('change', (e) => {
			form.querySelector('input[name="sort"]').value = e.target.value
			form.submit()
		})
	}
}

if (nowSellingFormEl) {
	initNowSellingFilterSort(nowSellingFormEl)
}
