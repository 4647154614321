import L from "leaflet"
import 'leaflet/dist/leaflet.css'

/**
 * initMap
 *
 * Renders a Google Leaflet Map onto the selected element

 *
 * @date    22/10/19
 * @since   5.8.6
 *
 */
const initLeafletMap = (el, options = {}) => {
    const mapOptions = {
        // defaults here
        ...options
    }

    // Instantiate the map
    const map = L.map(el, mapOptions)

    // Define and add the tile layer
    const tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // attribution: '&copy <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 20,
    }).addTo(map)
}

// GO Time for leaflet map
const mapEls = document.querySelectorAll('.leaflet-map')
mapEls.forEach((el) => {
    const options = {
        center: [(el.dataset.lat ?? -34.92865456437388), el.dataset.lng ?? 138.60000119029363],
        zoom: el.dataset.zoom ?? 16,
    }
    initLeafletMap(el, options)

    console.log(options)
})