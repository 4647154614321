import {
  elGetParents,
  elGetOffset,
  elSetCSS,
  elToggleClasses,
} from '../utils/element-helpers';

/**
 * Scroller
 * v3.2.4
 */
const scroller = () => {
  const htmlEl = document.querySelector('html');

  let scrollers = {},
    previousSectionId,
    currentSectionId,
    currentSectionEl,
    tracing,
    traceCss,
    doTraceOffscreen,
    elEvents,
    ww,
    wh,
    mxp,
    myp,
    x2,
    y2,
    itemX,
    itemY,
    itemW,
    itemH,
    i,
    isAbove,
    isAbove2,
    isBelow,
    isBelow2,
    isBefore,
    isBefore2,
    isAfter,
    isAfter2,
    isSpreadY,
    isContainedY,
    isEnteredAbove,
    isEnteringAbove,
    isExitingAbove,
    isEnteredBelow,
    isEnteringBelow,
    isExitingBelow,
    isSpreadX,
    isContainedX,
    isEnteredBefore,
    isEnteringBefore,
    isExitingBefore,
    isEnteredAfter,
    isEnteringAfter,
    isExitingAfter,
    isOffscreen,
    wasOffscreen,
    beenViewed,
    beenViewedCustom,
    viewedCustom,
    scrollLeftMax,
    scrollLeftPercent,
    scrollTopMax,
    scrollTopPercent,
    scrollDirectionRight,
    scrollDirectionDown,
    distanceTop,
    distanceLeft,
    isPassedBufferY,
    isPassedBufferX,
    isScrollingRight,
    isScrollingDown,
    scrollTopPivot,
    scrollLeftPivot;

  // constants
  const SCROLL_DIRECTION_BUFFER = 16; // px
  const SCROLL_TOP_DISTANCE = 32; // px
  const SCROLL_HEADER_DISTANCE = 160; // px

  const PREFIX = 'data-scroller';
  const ID = `${PREFIX}-id`;
  const ATTR_ITEM = `${PREFIX}-item`;
  const ATTR_CONTAINER = `${PREFIX}-container`;
  const ATTR_TRACE = `${PREFIX}-trace`;
  const ATTR_TRACE_OFFSCREEN = `${ATTR_TRACE}-offscreen`;
  const ATTR_SECTION = `${PREFIX}-section`;
  const ATTR_VIEWED_CUSTOM = `${PREFIX}-viewed-custom`;
  const ATTR_SECTION_CURRENT = `${ATTR_SECTION}-current`;
  const ATTR_SECTION_LABEL = `${ATTR_SECTION}-label`;

  // container states
  const IS_SCROLL_ZERO = 'is-scroll-zero';
  const IS_SCROLL_TOP = 'is-scroll-top';
  const IS_SCROLL_HEADER = 'is-scroll-header';
  const IS_SCROLLING = 'is-scrolling';
  const IS_SCROLLING_UP = `${IS_SCROLLING}--up`;
  const IS_SCROLLING_DOWN = `${IS_SCROLLING}--down`;
  const IS_SCROLLING_LEFT = `${IS_SCROLLING}--left`;
  const IS_SCROLLING_RIGHT = `${IS_SCROLLING}--right`;

  // item states
  const IS_VIEWED = 'is-viewed';
  const IS_VIEWED_CUSTOM = 'is-viewed-custom';
  const IS_SPREAD = 'is-spread';
  const IS_SPREAD_X = `${IS_SPREAD}--x`;
  const IS_SPREAD_Y = `${IS_SPREAD}--y`;
  const IS_CONTAINED = 'is-contained';
  const IS_CONTAINED_X = `${IS_CONTAINED}--x`;
  const IS_CONTAINED_Y = `${IS_CONTAINED}--y`;
  const IS_OFFSCREEN = 'is-offscreen';
  const IS_OFFSCREEN_ABOVE = `${IS_OFFSCREEN}--above`;
  const IS_OFFSCREEN_BELOW = `${IS_OFFSCREEN}--below`;
  const IS_OFFSCREEN_BEFORE = `${IS_OFFSCREEN}--before`;
  const IS_OFFSCREEN_AFTER = `${IS_OFFSCREEN}--after`;
  const IS_ENTERING = 'is-entering';
  const IS_ENTERING_ABOVE = `${IS_ENTERING}--above`;
  const IS_ENTERING_BELOW = `${IS_ENTERING}--below`;
  const IS_ENTERING_BEFORE = `${IS_ENTERING}--before`;
  const IS_ENTERING_AFTER = `${IS_ENTERING}--after`;
  const IS_ENTERED = 'is-entered';
  const IS_ENTERED_ABOVE = `${IS_ENTERED}--above`;
  const IS_ENTERED_BELOW = `${IS_ENTERED}--below`;
  const IS_ENTERED_BEFORE = `${IS_ENTERED}--before`;
  const IS_ENTERED_AFTER = `${IS_ENTERED}--after`;
  const IS_EXITING = 'is-exiting';
  const IS_EXITING_ABOVE = `${IS_EXITING}--above`;
  const IS_EXITING_BELOW = `${IS_EXITING}--below`;
  const IS_EXITING_BEFORE = `${IS_EXITING}--before`;
  const IS_EXITING_AFTER = `${IS_EXITING}--after`;

  // events
  const EVENT_SECTIONS_INIT = 'scroller-sections-init';
  const EVENT_SECTION_UPDATE = 'scroller-section-update';
  const EVENT_VIEWED_FIRST = 'scroller-viewed-first';
  const EVENT_VIEWED_CUSTOM = 'scroller-viewed-custom';

  /**
   * Update Scroll Container
   * @param {Object} data
   */
  const updateContainer = (data) => {
    // current state
    const { state } = data;

    // updated state
    const {
      scrollLeft,
      scrollTop,
      clientWidth,
      clientHeight,
      scrollWidth,
      scrollHeight,
    } = data.el;

    // logic
    scrollLeftMax = scrollWidth - clientWidth;
    scrollLeftPercent = scrollLeft / scrollLeftMax || 0;
    scrollTopMax = scrollHeight - clientHeight;
    scrollTopPercent = scrollTop / scrollTopMax;
    scrollDirectionRight = state.scrollLeft < scrollLeft;
    scrollDirectionDown = state.scrollTop < scrollTop;
    distanceTop = Math.abs(state.scrollTopPivot - scrollTop);
    distanceLeft = Math.abs(state.scrollLeftPivot - scrollLeft);
    isPassedBufferY = distanceTop > SCROLL_DIRECTION_BUFFER;
    isPassedBufferX = distanceLeft > SCROLL_DIRECTION_BUFFER;
    isScrollingRight = isPassedBufferX
      ? scrollDirectionRight
      : state.isScrollingRight;
    isScrollingDown = isPassedBufferY
      ? scrollDirectionDown
      : state.isScrollingDown;
    scrollTopPivot = state.scrollTopPivot || 0;
    scrollLeftPivot = state.scrollLeftPivot || 0;

    if (state.isScrollingDown === scrollDirectionDown) {
      // continuing direction
    } else if (isPassedBufferY) {
      scrollTopPivot = scrollTop;
    }

    if (state.isScrollingRight === scrollDirectionRight) {
      // continuing direction
    } else if (isPassedBufferX) {
      scrollLeftPivot = scrollLeft;
    }

    // update state
    data.state = {
      ...data.state,
      scrollLeft,
      scrollTop,
      scrollTopPivot,
      scrollLeftPivot,
      isScrollingDown,
      isScrollingRight,
      clientWidth,
      clientHeight,
      scrollWidth,
      scrollHeight,
    };

    // clear and set timeout
    clearTimeout(data.timeout);
    data.timeout = setTimeout(() => {
      elToggleClasses(data.el, {
        [IS_SCROLLING]: false,
      });
    }, 1000);

    // update classes
    elToggleClasses(data.el, {
      [IS_SCROLLING]: true,
      [IS_SCROLL_ZERO]: scrollTop === 0,
      [IS_SCROLL_TOP]: scrollTop < SCROLL_TOP_DISTANCE,
      [IS_SCROLL_HEADER]: scrollTop < SCROLL_HEADER_DISTANCE,
      [IS_SCROLLING_UP]: !isScrollingDown,
      [IS_SCROLLING_DOWN]: isScrollingDown,
      [IS_SCROLLING_LEFT]: !isScrollingRight,
      [IS_SCROLLING_RIGHT]: isScrollingRight,
    });
  };

  /**
   * Update Item
   * @param {Element} el
   * @param {Object} data
   */
  const updateItem = (el, data) => {
    const {
      scrollLeft: x,
      scrollTop: y,
      clientWidth: w,
      clientHeight: h,
      scrollWidth: sw,
      scrollHeight: sh,
      isScrollingDown,
      isScrollingRight,
    } = data.state;

    x2 = x + w;
    y2 = y + h;
    itemX = elGetOffset(el).left;
    itemY = elGetOffset(el).top;
    itemW = el.offsetWidth ?? el.clientWidth;
    itemH = el.offsetHeight ?? el.clientHeight;
    i = {
      x: itemX,
      y: itemY,
      w: itemW,
      h: itemH,
      x2: itemX + itemW,
      y2: itemY + itemH,
    };

    isAbove2 = i.y2 < y;
    isBelow = i.y > y2;
    isBefore2 = i.x2 < x;
    isAfter = i.x > x2;
    isOffscreen = isAbove2 || isBelow || isBefore2 || isAfter;
    wasOffscreen = el.classList.contains(IS_OFFSCREEN);

    if (wasOffscreen && isOffscreen) return;

    isAbove = i.y < y;
    isBelow2 = i.y2 > y2;
    isBefore = i.x < x;
    isAfter2 = i.x2 > x2;
    isSpreadY = isAbove && isBelow2;
    isContainedY = !isAbove && !isBelow2;
    isEnteredAbove = isAbove2;
    isEnteringAbove = isAbove && !isAbove2 && !isScrollingDown;
    isExitingAbove = isAbove && !isAbove2 && isScrollingDown;
    isEnteredBelow = !isBelow2;
    isEnteringBelow = !isBelow && isBelow2 && isScrollingDown;
    isExitingBelow = !isBelow && isBelow2 && !isScrollingDown;
    isSpreadX = isBefore && isAfter2;
    isContainedX = !isBefore && !isAfter2;
    isEnteredBefore = isBefore2;
    isEnteringBefore = isBefore && !isBefore2 && !isScrollingRight;
    isExitingBefore = isBefore && !isBefore2 && isScrollingRight;
    isEnteredAfter = !isAfter2;
    isEnteringAfter = !isAfter && isAfter2 && isScrollingRight;
    isExitingAfter = !isAfter && isAfter2 && !isScrollingRight;
    beenViewed = el.classList.contains(IS_VIEWED);
    beenViewedCustom = el.classList.contains(IS_VIEWED_CUSTOM);

    /**
     * Specific events
     */
    elEvents = el.getAttribute('data-scroller-events');

    /**
     * Onscreen percentage
     *
     * [screen][element][screen][element]
     * b=bottom, t=top
     */
    // Y axis only, add X later if needed
    tracing = el.getAttribute(ATTR_TRACE);
    doTraceOffscreen = el.getAttribute(ATTR_TRACE_OFFSCREEN) != undefined;
    if (tracing) {
      if (!(isAbove2 || isBelow) || doTraceOffscreen) {
        traceCss = {};

        if (tracing.includes('bttb') || tracing == '') {
          traceCss = {
            ...traceCss,
            '--bttb': (y - (i.y - wh)) / (wh + i.h),
          };
        }
        if (tracing.includes('bttt')) {
          traceCss = {
            ...traceCss,
            '--bttt': (y - (i.y - wh)) / wh,
          };
        }
        if (tracing.includes('btbb')) {
          traceCss = {
            ...traceCss,
            '--btbb': (y - (i.y - wh)) / i.h,
          };

          if (elEvents && elEvents.includes('btbb')) {
            el.dispatchEvent(
              new CustomEvent('scroller-trace-btbb', {
                detail: {
                  value: traceCss['--btbb'],
                },
              })
            );
          }
        }
        if (tracing.includes('bbtt')) {
          traceCss = {
            ...traceCss,
            '--bbtt': (y - (i.y - wh + i.h)) / (wh - i.h),
          };
        }
        if (tracing.includes('tttb')) {
          traceCss = {
            ...traceCss,
            '--tttb': (y - i.y) / i.h,
          };
        }
        if (tracing.includes('ttbb')) {
          traceCss = {
            ...traceCss,
            '--ttbb': (y - i.y) / (i.h - wh),
          };
        }

        elSetCSS(el, traceCss);
      }

      /**
       * Offscreen clamps
       */
      if (
        tracing.includes('tttb') &&
        (isAbove2 || isBelow) &&
        !doTraceOffscreen
      ) {
        elSetCSS(el, {
          '--tttb': isAbove2 ? 1 : 0,
        });
      }

      if (tracing.includes('btbb') && isBelow && !doTraceOffscreen) {
        elSetCSS(el, {
          '--btbb': 0,
        });
        if (elEvents && elEvents.includes('btbb')) {
          el.dispatchEvent(
            new CustomEvent('scroller-trace-btbb', {
              detail: {
                value: 0,
              },
            })
          );
        }
      }
    }

    /**
     * Custom
     */
    viewedCustom = el.getAttribute(ATTR_VIEWED_CUSTOM) ?? null;
    if (viewedCustom && !beenViewedCustom) {
      if ((y - (i.y - wh)) / i.h >= parseFloat(viewedCustom)) {
        el.classList.add(IS_VIEWED_CUSTOM);
        el.dispatchEvent(new CustomEvent(EVENT_VIEWED_CUSTOM));
      }
    }

    /**
     * Events
     */
    if (isContainedX && isContainedY && !beenViewed) {
      el.dispatchEvent(new CustomEvent(EVENT_VIEWED_FIRST));
    }

    if (elEvents) {
      if (
        elEvents.includes(IS_ENTERING_BELOW) &&
        isEnteringBelow &&
        !el.classList.contains(IS_ENTERING_BELOW)
      ) {
        el.dispatchEvent(new CustomEvent('scroller-' + IS_ENTERING_BELOW));
      }

      if (
        elEvents.includes(IS_EXITING_BELOW) &&
        isExitingBelow &&
        !el.classList.contains(IS_EXITING_BELOW)
      ) {
        el.dispatchEvent(new CustomEvent('scroller-' + IS_EXITING_BELOW));
      }

      if (
        elEvents.includes(IS_CONTAINED_Y) &&
        isContainedY &&
        !el.classList.contains(IS_CONTAINED_Y)
      ) {
        el.dispatchEvent(new CustomEvent('scroller-' + IS_CONTAINED_Y));
      }

      if (
        elEvents.includes(IS_OFFSCREEN_ABOVE) &&
        isAbove2 &&
        !el.classList.contains(IS_OFFSCREEN_ABOVE)
      ) {
        el.dispatchEvent(new CustomEvent('scroller-' + IS_OFFSCREEN_ABOVE));
      }
    }

    /**
     * Toggle classes based on state
     */
    elToggleClasses(el, {
      [IS_VIEWED]: beenViewed || (isContainedX && isContainedY),
      [IS_SPREAD]: isSpreadX || isSpreadY,
      [IS_SPREAD_X]: isSpreadX,
      [IS_SPREAD_Y]: isSpreadY,
      [IS_CONTAINED]: isContainedX || isContainedY,
      [IS_CONTAINED_X]: isContainedX,
      [IS_CONTAINED_Y]: isContainedY,
      [IS_OFFSCREEN]: isOffscreen,
      [IS_OFFSCREEN_ABOVE]: isAbove2,
      [IS_OFFSCREEN_BELOW]: isBelow,
      [IS_OFFSCREEN_BEFORE]: isBefore2,
      [IS_OFFSCREEN_AFTER]: isAfter,
      [IS_ENTERING]:
        isEnteringAbove ||
        isEnteringBelow ||
        isEnteringBefore ||
        isEnteringAfter,
      [IS_ENTERING_ABOVE]: isEnteringAbove,
      [IS_ENTERING_BELOW]: isEnteringBelow,
      [IS_ENTERING_BEFORE]: isEnteringBefore,
      [IS_ENTERING_AFTER]: isEnteringAfter,
      [IS_ENTERED]:
        isEnteredAbove || isEnteredBelow || isEnteredBefore || isEnteredAfter,
      [IS_ENTERED_ABOVE]: isEnteredAbove,
      [IS_ENTERED_BELOW]: isEnteredBelow,
      [IS_ENTERED_BEFORE]: isEnteredBefore,
      [IS_ENTERED_AFTER]: isEnteredAfter,
      [IS_EXITING]:
        isExitingAbove || isExitingBelow || isExitingBefore || isExitingAfter,
      [IS_EXITING_ABOVE]: isExitingAbove,
      [IS_EXITING_BELOW]: isExitingBelow,
      [IS_EXITING_BEFORE]: isExitingBefore,
      [IS_EXITING_AFTER]: isExitingAfter,
    });
  };

  /**
   * Update Items
   * @param {Object} data
   */
  const updateItems = (data) => {
    data.items.forEach((el) => {
      updateItem(el, data);
    });

    if (!data.children.length) return;

    for (const [key, value] of Object.entries(data.children)) {
      updateItem(value.el, data);
      updateItems(value);
    }
  };

  /**
   * Update Sections
   * @param {Object} data
   */
  const updateSections = (data) => {
    if (!data.sections.length) return;

    previousSectionId = data.el.getAttribute(ATTR_SECTION_CURRENT);
    currentSectionEl = null;

    data.sections
      .slice()
      .reverse()
      .forEach((el) => {
        if (!currentSectionEl && data.state.scrollTop >= elGetOffset(el).top) {
          currentSectionEl = el;
          return;
        }
      });

    currentSectionId = currentSectionEl
      ? currentSectionEl.getAttribute(ATTR_SECTION)
      : null;

    if (previousSectionId != currentSectionId) {
      data.el.setAttribute(ATTR_SECTION_CURRENT, currentSectionId);
      data.el.dispatchEvent(
        new CustomEvent(EVENT_SECTION_UPDATE, {
          detail: {
            el: currentSectionEl,
            id: currentSectionId,
            label: currentSectionEl
              ? currentSectionEl.getAttribute(ATTR_SECTION_LABEL)
              : null,
          },
        })
      );
    }
  };

  /**
   * On Scroll
   * @param {Object} data
   */
  const onScroll = (data) => {
    if (!data) return;
    updateContainer(data);
    updateItems(data);
    updateSections(data);
  };

  const setWindowSize = () => {
    ww = window.innerWidth;
    wh = window.innerHeight;
  };

  /**
   * On Resize
   * @param {Object} data
   */
  const onResize = (data) => {
    setWindowSize();
    onScroll(data);
  };

  /**
   * Build Tree
   * @param {String[]} tree
   */
  const buildTree = (tree) => {
    let obj = scrollers;

    tree.forEach((id) => {
      if (obj[id]) {
        // Progress to children
        obj = obj[id].children;
      } else {
        // Create
        const el = document.querySelector(`[${ATTR_CONTAINER}][${ID}="${id}"]`);
        const scrollerEl = id === 'html' ? window : el;

        const data = (obj[id] = {
          scrollerEl,
          el,
          state: {
            scrollLeft: 0,
            scrollTop: 0,
            scrollTopPivot: 0,
            scrollLeftPivot: 0,
            isScrollingDown: true,
            isScrollingRight: true,
          },
          items: [],
          sections: [],
          children: {},
          timeout: () => {},
        });

        scrollerEl.addEventListener('scroll', () => {
          onScroll(data);
        });
        window.addEventListener('resize', () => {
          onResize(data);
        });
      }
    });
  };

  /**
   * Get Tree Ids
   * @param {Element} el
   */
  const getTreeIds = (el) => {
    const itemParentEls = elGetParents(el, `[${ATTR_CONTAINER}]`);
    const treeIds = itemParentEls.reverse().map((el) => el.getAttribute(ID));
    return treeIds;
  };

  /**
   * Init
   */
  const init = () => {
    const itemEls = document.querySelectorAll(`[${ATTR_ITEM}]`);
    const sectionEls = document.querySelectorAll(`[${ATTR_SECTION}]`);

    // Add main scroller container data attributes
    htmlEl.setAttribute(ID, 'html');
    htmlEl.setAttribute(ATTR_CONTAINER, '');

    setWindowSize();

    // Global Mouse Position
    if (htmlEl.getAttribute('data-scroller-trace') == 'mouse') {
      htmlEl.addEventListener('mousemove', ({ clientX: mx, clientY: my }) => {
        mxp = mx / ww;
        myp = my / wh;
        mxp = mxp < 0 ? 0 : mxp > 1 ? 1 : mxp;
        myp = myp < 0 ? 0 : myp > 1 ? 1 : myp;

        elSetCSS(htmlEl, {
          '--mx': mxp,
          '--my': myp,
        });
      });
    }

    // Build tree and add listeners
    itemEls.forEach((el) => {
      const tree = getTreeIds(el);
      buildTree(tree);
    });

    // Add items to tree
    itemEls.forEach((el) => {
      const tree = getTreeIds(el);
      const obj = tree.reduce((o, v) => o[v] || o.children[v], scrollers);
      obj.items.push(el);
    });

    // Add sections to tree
    sectionEls.forEach((el) => {
      const tree = getTreeIds(el);
      const obj = tree.reduce((o, v) => o[v] || o.children[v], scrollers);
      obj.sections.push(el);
    });

    // Init events
    for (const [key, value] of Object.entries(scrollers)) {
      if (!value.sections.length) return;

      value.el.dispatchEvent(
        new CustomEvent(EVENT_SECTIONS_INIT, {
          detail: {
            sections: value.sections.map((el) => {
              return {
                id: el.getAttribute(ATTR_SECTION),
                label: el.getAttribute(ATTR_SECTION_LABEL),
              };
            }),
          },
        })
      );
    }
  };

  init();
};

document.addEventListener('DOMContentLoaded', scroller);

export default scroller;
