import { Loader } from "@googlemaps/js-api-loader"

const additionalOptions = {
    libraries: ["marker"],
}

const gmapsLoader = new Loader({
    apiKey: window.gMapsPublicApiKey,
    version: "beta",
    ...additionalOptions,
})

/**
 * initMap
 *
 * Renders a Google Map onto the selected element
 * 
 * This is our traditional map renderer, using the Google Maps JS API and element data attributes.
 * We can instead use the new Google Maps Web Component, which is more up-to-date and easier to use.
 * (see https://developers.google.com/maps/documentation/javascript/add-google-map#gmp-map-element)
 * This is likely legacy code and will be removed in the future.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   HTMLElement el The HTML element.
 * @param   object mapArgs optional map arguments/overrides
 * @return  object The map instance.
 */
const initMap = (el, mapArgs = {}) => {

    console.debug('intialised a Google Map', el)

    let zoom = parseInt(el.dataset.zoom) || 16
    let center = (el.dataset.centerLat && el.dataset.centerLng) ? {lat: parseFloat(el.dataset.centerLat), lng: parseFloat(el.dataset.centerLng)} : {lat: -34.397, lng: 150.644}
    let mapId = el.dataset.mapId || null
    let infoWindow = new google.maps.InfoWindow({})

    let gMapMarkers = []
    // Initialize an array for markers on the map instance
    let markersData = JSON.parse(el.dataset.markers || '[]')

    // merge additional options into mapArgs
    mapArgs = {
        ...mapArgs,
        ...JSON.parse(el.dataset.additionalOptions || '{}'),
    }

    // merge default map args with any passed in
    mapArgs = {
        zoom: zoom,
        center: center,
        mapId: mapId,
        ...mapArgs,
    }
    let map = new google.maps.Map(el, mapArgs)

    // Make the info window close when clicking anywhere on the map.
    google.maps.event.addListener(map, 'click', () => { infoWindow.close(); })

    // Add markers if they exist
    markersData.forEach((markerSettings) => {
        // if markerSettings is an object and has at least the position property, then it's valid
        if (typeof markerSettings === 'object' && markerSettings.position) {
            // cast each item in the position property to a float
            markerSettings.position = {
                lat: parseFloat(markerSettings.position.lat),
                lng: parseFloat(markerSettings.position.lng),
            }
            const markerInstance = initMarker(markerSettings, map, infoWindow)
            gMapMarkers.push(markerInstance)
        }
    })

    // helper to close all other markers
    window.closeOtherMarkers = function() {
        // close all other markers
        gMapMarkers.forEach(marker => {
            marker.infoWindow.close()
        })
    }

    // Center map based on markers.
    centerMap(map, gMapMarkers)

    // Return map instance.
    return map
}

/**
 * initMarker
 *
 * Creates a marker for the given HTML element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object markerSettings The marker settings.
 * @param   object map The map instance.
 * @return  object The marker instance.
 */
const initMarker = (markerSettings, map, infoWindow) => {

    // merge default settings with any passed in
    const theSettings = {
        map: map,
        ...markerSettings,
    }

    // Create marker instance on the map.
    let marker = new google.maps.Marker(theSettings)

    // Link marker to DOM element
    const linkedElement = document.getElementById(markerSettings.linkedElement || '')
    const sharedSelector = markerSettings.sharedSelector || ''

    if (linkedElement) {
        // Event listener for DOM element click
        linkedElement.addEventListener("click", () => {
            map.setZoom(marker.zoom || 18)
            map.panTo(marker.position)
            // set the info window to the marker
            infoWindow.setOptions({
                content: marker.content,
                maxWidth: 350,
                ariaLabel: marker.title,
            })
            infoWindow.open(map, marker)

            document.querySelectorAll(`.${sharedSelector}`).forEach(el => el.classList.remove("active"))
            linkedElement.classList.add("active")
        });
    } else {
        console.warn('No linked element found for marker', markerSettings)
    }

    // Show info window when marker is clicked.
    marker.addListener("click", function () {
        // move map to marker
        map.setZoom(marker.zoom || 18)
        map.panTo(marker.position)

        // Remove active class from all shared selectors
        document.querySelectorAll(`.${sharedSelector}`).forEach(el => el.classList.remove("active"));
        // Add active class to linked element
        if (linkedElement) {
            linkedElement.classList.add("active");
        } else {
            console.warn('Can\'t highlight linked element for marker.. linkedElement is not set', markerSettings)
        }

        // fire custom event for Alpine.js to pick up
        window.dispatchEvent(new CustomEvent('markerclicked', { detail: { marker: marker } }))

        // If marker contains HTML, add it to an infoWindow.
        if (theSettings.content) {
            infoWindow.setOptions({
                content: theSettings.content,
                maxWidth: 350,
                ariaLabel: theSettings.title,
            })
            infoWindow.open(map, marker)
        }
    })

    // Return marker instance
    return marker
}

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
const centerMap = (map, markers = []) => {
    // Create map boundaries from all map markers.
    const bounds = new google.maps.LatLngBounds()

    markers.forEach((marker) => {
        // marker is a google.maps.Marker instance here
        bounds.extend(marker.position)
    })

    // Case: Single marker.
    if (markers.length == 1) {
        map.setCenter(bounds.getCenter())
    // Case: Multiple markers.
    } else {
        map.fitBounds(bounds)
    }
}

// GO Time for maps
gmapsLoader.load().then(async () => {
    const { Map } = await google.maps.importLibrary("maps")

    // Render maps where found, via our traditional page element lookup.
    document.querySelectorAll(".g-map").forEach((el) => {
        if (!el) return
        initMap(el)
    })
})