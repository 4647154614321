import Cookies from "js-cookie"

document.addEventListener("DOMContentLoaded", function() {
	const alertBar = document.querySelectorAll("[data-alert]");
	alertBar.forEach(bar => {
		const alertId = !!bar ? bar.dataset.alert : null;
		const dismissAlert = bar.querySelector("[data-dismiss-alert]");
	
		if (!!dismissAlert && !!bar) {
			dismissAlert.addEventListener("click", () => {
				Cookies.set(`${alertId}`, true, { expires: 7 })
				slideUp(bar)
			})
		}
	})
})

/**
 * Slide element up (like jQuery's slideUp)
 * @param  {Node} elem Element
 * @return {[type]}      [description]
 */
function slideUp(elem) {
	// elem.style.maxHeight = "0"
	// once(1, function() {
	// 	elem.style.opacity = "0"
	// 	elem.style.visibility = "hidden"
	// })
	elem.remove()
}

/**
 * Call once after timeout
 * @param  {Number}   seconds  Number of seconds to wait
 * @param  {Function} callback Callback function
 */
function once(seconds, callback) {
	var counter = 0
	var time = window.setInterval(function() {
		counter++
		if (counter >= seconds) {
			callback()
			window.clearInterval(time)
		}
	}, 400)
}
